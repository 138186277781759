<template>
    <div class="mine">
        <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
        <div class="mine-head">
            <div>
                <div class="mine-head-personal">
                    <img src="../assets/img/default-people.png" class="mine-head-img"/>
                    <div class="mine-head-text">
                        <div class="mine-head-name" v-if="user.company">
                            {{ user.company.username }}
                        </div>
                        <div class="mine-head-other">
                            <i class="van-icon van-icon-user-o"></i>
                            {{ user.realname }}
                        </div>
                        <div class="mine-head-other">
                            <i class="van-icon van-icon-send-gift"></i>
                            {{ user.email }}
                        </div>
                        <div class="mine-head-other" v-if="user.mobile">
                            <i class="van-icon van-icon-phone"></i>
                            {{ user.mobile }}
                        </div>

                    </div>
                </div>
                <!-- <div class="mine-head-people">
                  <div class="mine-head-list">
                    <div class="mine-head-num">50</div>
                    <div class="mine-head-title">我的获客</div>
                  </div>
                  <div class="mine-head-list">
                    <div class="mine-head-num">4</div>
                    <div class="mine-head-title">今日获客</div>
                  </div>
                  <div class="mine-head-list" @click="$router.push('/code')">
                    <img class="mine-head-icon" src="../assets/img/icon-money.png" />
                    <div class="mine-head-title">{{ $t("积分钱包") }}</div>
                  </div>
                </div> -->
            </div>
        </div>
        <div class="mine-body">
            <div class="mine-block">
                <!-- <div class="mine-block-list" @click="$router.push('/code')">
                  <div class="mine-block-left">
                    <img src="../assets/img/icon-grade.png" />
                    <div>{{ $t("我的积分") }}</div>
                  </div>
                  <img class="mine-block-right" src="../assets/img/icon-right.png" />
                </div> -->

                <div class="mine-block-list clipboardBtn" @click="copy">
                    <div class="mine-block-left">
                        <img src="../assets/img/icon-share.png"/>
                        <div>{{ $t("分享链接") }}</div>
                    </div>
                    <img class="mine-block-right" src="../assets/img/icon-right.png"/>
                </div>
                <div v-if="user.type==3" class="mine-block-list clipboardBtn" @click="changeIdentity">
                    <div class="mine-block-left">
                        <img src="../assets/img/icon-user.png"/>
                        <div>{{ identity }}</div>
                    </div>
                    <img class="mine-block-right" src="../assets/img/icon-right.png"/>
                </div>
                <div class="mine-block-list clipboardBtn" @click="$router.push('/bind')">
                    <div class="mine-block-left">
                        <img src="../assets/img/icon-user.png"/>
                        <div>{{ $t("账号绑定") }}</div>
                    </div>
                    <img class="mine-block-right" src="../assets/img/icon-right.png"/>
                </div>

                <div class="mine-block-list" @click="$router.push('/grade')">
                    <div class="mine-block-left">
                        <img src="../assets/img/icon-grade.png"/>
                        <div>{{ $t("等级购买") }}</div>
                    </div>
                    <img class="mine-block-right" src="../assets/img/icon-right.png"/>
                </div>

                <div class="mine-block-list" @click="$router.push('/set')">
                    <div class="mine-block-left">
                        <img src="../assets/img/icon-set.png"/>
                        <div>{{ $t("设置") }}</div>
                    </div>
                    <img class="mine-block-right" src="../assets/img/icon-right.png"/>
                </div>
            </div>
            <div class="mine-block">
                <div class="mine-block-list" @click="loginOut">
                    <div class="mine-block-left">
                        <!-- <img src="../assets/img/icon-set.png" /> -->
                        <div>{{ $t("退出登录") }}</div>
                    </div>
                    <img class="mine-block-right" src="../assets/img/icon-right.png"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    // import HelloWorld from '@/components/HelloWorld.vue'
    import Clipboard from "clipboard";
    import {Toast} from "vant";
    import {Dialog} from 'vant';

    export default {
        name: "Mine",
        data() {
            return {
                identity: this.$t("前往销售"),
                user: "",
                share: "",
            };
        },
        mounted() {
            this.userCenter();
        },
        methods: {
            copy() {
                // 复制
                let _this = this
                let clipboard = new Clipboard(".clipboardBtn", {
                    text: () => {
                        return _this.share;
                    },
                });
                clipboard.on("success", () => {
                    Toast(_this.$t('复制成功'))
                    // 释放内存
                    clipboard.destroy();
                });
                clipboard.on("error", () => {
                    // 不支持复制
                    Toast(_this.$t('该浏览器不支持自动复制'))
                    // 释放内存
                    clipboard.destroy();
                });
            },
            loginOut() {
                // 退出登录
                this.$store.commit("setToken", "");
                this.$router.push("/login");
            },
            userCenter() {
                let that = this;
                // 获取用户数据
                this.$http.post("/v1/userCenter").then((res) => {
                    if (res.code == 200) {
                        that.user = res.data.user;
                        that.share = res.data.invite_url;
                        that.identity = that.user.now_identity == 1 ? this.$t("前往销售") : this.$t("前往采购");
                        that.$store.commit("setIdentity", res.data.user.now_identity);
                    }
                });
            },
            changeIdentity() {
                let that = this;
                Dialog.confirm({
                    message: this.$t("确认切换身份"),
                    confirmButtonText:this.$t("确认"),
                    cancelButtonText:this.$t("取消"),
                }).then(() => {
                    // 切换用户
                    this.$http.post("/v1/switchClient").then((res) => {
                        if (res.code == 200) {
                            Toast(res.msg)
                            that.userCenter();
                        }
                    });
                }).catch(() => {
                    // on cancel
                });

            },
        },
    };
</script>

<style scoped>
    .mine {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        background: #f5f6fa;
    }

    .mine-head {
        flex-shrink: 0;
        padding: 0 16px;
        box-sizing: border-box;
        background: url("../assets/img/head.png");
        background-size: 100%;
        width: 100vw;
        height: 73.5vw;
        color: white;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .mine-head-personal {
        margin-top: 10vw;
        display: flex;
        flex-direction: row;
        padding: 40px 14px 30px;
    }

    .mine-head-img {
        width: 60px;
        height: 60px;
        border-radius: 60px;
        border: 3px solid white;
    }

    .mine-head-text {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        justify-content: space-around;
    }

    .mine-head-name {
        font-size: 16px;
    }

    .mine-head-other {
        line-height: 20px;
    }

    .mine-head-people {
        display: flex;
        justify-content: space-around;
    }

    .mine-head-list {
        display: flex;
        flex-direction: column;
        height: 45px;
        align-items: center;
        justify-content: space-between;
    }

    .mine-head-num {
        font-size: 20px;
    }

    .mine-head-icon {
        width: 22px;
        height: 22px;
    }

    .mine-head-title {
        color: #efefef;
        font-size: 12px;
    }

    /* .mine-head-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 16px;
      background: #444;
      box-shadow: 0 0 5px 0 #444;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    } */
    /* .mine-head-explain {
      color: white;
    }
    .mine-head-qrcode {
      width: 25px;
      height: 25px;
    } */
    .mine-body {
        flex: 1;
        padding: 16px;
    }

    .mine-block {
        box-shadow: 0 0 8px 0 #eee;
        padding: 0 16px;
        margin-bottom: 16px;
        border-radius: 5px;
        overflow: hidden;
        background: white;
    }

    .mine-block-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;
        border-bottom: 1px solid #f5f5f5;
    }

    .mine-block-list:last-child {
        border: none;
    }

    .mine-block-left {
        display: flex;
        align-items: center;
    }

    .mine-block-left > img {
        width: 25px;
        height: 25px;
    }

    .mine-block-left > div {
        margin-left: 8px;
        color: #333;
    }

    .mine-block-right {
        width: 15px;
        height: 15px;
    }

    .mine-guest {
        background: white;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 0 8px 0 #eee;
    }

    .mine-guest-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px;
        box-shadow: 0 0 10px 0 #eee;
        margin-bottom: 5px;
    }

    .mine-guest-title {
        font-weight: bold;
    }

    .mine-guest-more {
        font-size: 12px;
        color: #888;
    }

    .mine-guest-block {
        padding: 0 16px;
    }

    .mine-guest-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;
        border-bottom: 1px solid #f5f5f5;
    }

    .mine-guest-list:last-child {
        border: none;
    }

    .mine-guest-left {
        display: flex;
    }

    .mine-guest-img {
        width: 40px;
        height: 40px;
        border-radius: 40px;
    }

    .mine-guest-text {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 10px;
    }

    .mine-guest-name {
    }

    .mine-guest-time {
        font-size: 12px;
        color: #888;
    }

    .login-icon {
        width: 20px;
        height: 20px;
        margin-right: 4px;
    }
</style>